export default {
    metrika: {
        id: 26812653
    },

    defaults: {
        hooks: {
            onready: function () {},
            onshare: function () {}
        },

        theme: {
            bare: false,
            copy: 'last',
            lang: 'ru',
            limit: false,
            nonce: '',
            popupPosition: 'inner',
            popupDirection: 'bottom',
            services: 'collections,vkontakte,facebook,twitter',
            size: 'm',
            useLinks: false,
            direction: 'horizontal'
        },

        i18n: {
            az: {
                copyLink: 'Əlaqə',
                pressToCopy: 'Press ctrl+C and Enter to copy'
            },
            be: {
                copyLink: 'Cпасылка',
                pressToCopy: 'Press ctrl+C and Enter to copy'
            },
            en: {
                copyLink: 'Copy link',
                pressToCopy: 'Press ctrl+C and Enter to copy'
            },
            hy: {
                copyLink: 'Հղում',
                pressToCopy: 'Press ctrl+C and Enter to copy'
            },
            ka: {
                copyLink: 'ბმული',
                pressToCopy: 'Press ctrl+C and Enter to copy'
            },
            kk: {
                copyLink: 'Сілтеме',
                pressToCopy: 'Press ctrl+C and Enter to copy'
            },
            ro: {
                copyLink: 'Link',
                pressToCopy: 'Press ctrl+C and Enter to copy'
            },
            ru: {
                copyLink: 'Скопировать ссылку',
                pressToCopy: 'Чтобы скопировать, нажмите ctrl+С и enter'
            },
            tr: {
                copyLink: 'Bağlantı',
                pressToCopy: 'Press ctrl+C and Enter to copy'
            },
            tt: {
                copyLink: 'Сылтама',
                pressToCopy: 'Press ctrl+C and Enter to copy'
            },
            uk: {
                copyLink: 'Посилання',
                pressToCopy: 'Press ctrl+C and Enter to copy'
            },
            uz: {
                copyLink: 'Havoladan nusxa olish',
                pressToCopy: 'Nusxa olish uchun ctrl+С va enter tugmalarini bosing'
            }
        },

        content: {
            template: 'default',
            description: '',
            image: '',
            title: window.document.title,
            url: window.location.href
        },

        contentByService: {}
    }
};
