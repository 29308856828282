/* jscs:disable maximumLineLength */
export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'tg://msg_url',
                params: {
                    url: 'url',
                    text: 'title'
                }
            }
        }
    },

    i18n: {
        az: 'Telegram',
        be: 'Telegram',
        en: 'Telegram',
        hy: 'Telegram',
        ka: 'Telegram',
        kk: 'Telegram',
        ro: 'Telegram',
        ru: 'Telegram',
        tr: 'Telegram',
        tt: 'Telegram',
        uk: 'Telegram',
        uz: 'Telegram'
    },

    color: '#64a9dc'
};
