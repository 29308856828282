import initTemplates from './templates';
import { buildUrl } from './router';
import * as popup from './popup';
import bem from './bem';
import { copy, clip } from './clipboard';
import dom from './dom';
import createOptions from './options';

const visibilityClasses = [
    'ya-share2__popup_direction_top',
    'ya-share2__popup_direction_bottom'
];

const ICON_SIZE_M = 24;
const ICON_SIZE_S = 18;
const POPUP_POSITION_OFFSET_M = 4;
const POPUP_POSITION_OFFSET_S = 3;

/**
 * Получить список дополнительных полей для плагинов
 *
 * @param  {Object} plugins
 * @return {Object}
 */
function getContentOptions(plugins) {
    return Object.keys(plugins).reduce((acc, name) => {
        const config = plugins[name];

        if (config.contentOptions) {
            acc[name] = config.contentOptions;
        }

        return acc;
    }, {});
}

let isMetrikaLoaded = false;
let metrikaCallbacks = [];

function resolveMetrika() {
    isMetrikaLoaded = true;
    metrikaCallbacks.forEach(fn => fn());
    metrikaCallbacks = [];
}

function onMetrikaLoaded(callback) {
    if (isMetrikaLoaded) {
        callback();
    } else {
        metrikaCallbacks.push(callback);
    }
}

export default class {
    constructor(domNode, params) {
        const { plugins, defaults, options, metrika } = params;
        const contentOptions = getContentOptions(plugins);

        const namespace = 'ya-share2.' + Math.random();

        this._params = params;
        this._domNode = domNode;
        this._namespace = namespace;
        this._plugins = plugins;
        this._options = createOptions(contentOptions, defaults, domNode.dataset, options);

        const lang = this._options.get('theme.lang');
        this._i18n = this._options.get(`i18n.${lang}`);

        this._initLayout(plugins, namespace);
        this._bindEvents(metrika);

        domNode.classList.add('ya-share2');
        domNode.classList.add('ya-share2_inited');

        this._morePopup = bem.findInside(this._domNode, { block: 'ya-share2', elem: 'popup' })[0];

        if (this._options.get('theme.popupPosition') === 'outer') {
            this._moveMorePopupOutside();
        }

        this._options.get('hooks.onready').call(this);

        // Destroy method could be called inside the hook
        // so if you want to add any logic below
        // ensure that instance is not destroyed
    }

    _isDestroyed() {
        return this._domNode === null;
    }

    _moveMorePopupOutside() {
        // Оборачиваем выносимый попап в контейнер, чтобы сохранить визуальное представление
        var container = bem.findInside(this._domNode, { block: 'ya-share2', elem: 'container' })[0];

        this._morePopupContainer = document.createElement('div');
        this._morePopupContainer.style.position = 'absolute';

        this._morePopupContainer.style['pointer-events'] = 'none';
        this._morePopup.style['pointer-events'] = 'all';

        this._morePopupContainer.className = container.className;
        this._morePopupContainer.appendChild(this._morePopup);

        document.body.appendChild(this._morePopupContainer);
    }

    updateContent(content) {
        if (this._isDestroyed()) {
            throw new Error('Could not operate on destroyed block.');
        }

        this._options.merge({ content });
        this._initLayout(this._params.plugins, this._namespace);
    }

    updateContentByService(contentByService) {
        if (this._isDestroyed()) {
            throw new Error('Could not operate on destroyed block.');
        }

        this._options.merge({ contentByService });
        this._initLayout(this._params.plugins, this._namespace);
    }

    destroy() {
        this._domNode.classList.remove('ya-share2_inited');
        this._domNode.innerHTML = '';
        this._domNode = null;

        if (this._morePopupContainer) {
            dom.remove(this._morePopupContainer);
            this._morePopupContainer = null;
        }

        document.body.removeEventListener('click', this._onBodyClick);
        document.body.removeEventListener('keydown', this._onKeydown);
    }

    _getContentForService(name) {
        const getOption = key => this._options.get(key, name);

        const data = {
            title: getOption('content.title'),
            description: getOption('content.description'),
            image: getOption('content.image'),
            url: getOption('content.url')
        };

        const contentOptions = this._plugins[name].contentOptions || {};
        Object.keys(contentOptions).forEach(option => {
            data[option] = getOption(`content.${option}`);
        });

        return data;
    }

    _initLayout(plugins, namespace) {
        this._services = this._options.get('theme.services')
            .split(',')
            .filter(name => plugins[name])
            .map(name => {
                const getOption = key => this._options.get(key, name);

                const data = this._getContentForService(name);

                const shareUrlConfig = plugins[name].config.shareUrl;
                const template = getOption('content.template', name);
                const templateConfig = shareUrlConfig[template] || shareUrlConfig.default;

                let shareUrl = buildUrl(templateConfig, data);

                // Добавляем метку для Метрики
                shareUrl += '&utm_source=share2';

                return {
                    name: name,
                    title: plugins[name].i18n[getOption('theme.lang')],
                    location: shareUrl,
                    popupDimensions: plugins[name].popupDimensions
                };
            });

        const templates = initTemplates(this._i18n);

        templates.update(this._domNode, 'container', [{
            urls: {
                content: this._options.get('content.url')
            },
            theme: this._options.get('theme'),
            services: this._services,
            namespace: namespace
        }]);
    }

    getNonce() {
        return this._options.get('theme.nonce');
    }

    _bindEvents(metrika) {
        this._onBodyClick = this._onBodyClick.bind(this, metrika);
        this._onKeydown = this._onKeydown.bind(this);

        document.body.addEventListener('click', this._onBodyClick);
        document.body.addEventListener('keydown', this._onKeydown);
        document.addEventListener('yacounter' + metrika._id + 'inited', this._onMetrikaInited);

        onMetrikaLoaded(() => {
            metrika.getCounter().params({
                services: this._services.map(service => service.name).join(',')
            });
        });
    }

    _onKeydown(event) {
        const keyCode = event.which || event.keyCode;

        switch (keyCode) {
            case 27: // ESC
                this._closePopup();
                break;

            default:
                break;
        }
    }

    _onBodyClick(metrika, event) {
        const target = dom.getTarget(event);
        const targetContainer = bem.findOutside(target, { block: 'ya-share2', elem: 'container' });
        const container = bem.findInside(this._domNode, { block: 'ya-share2', elem: 'container' })[0];

        if (!targetContainer || (targetContainer !== container && targetContainer !== this._morePopupContainer)) {
            this._closePopup();

            return;
        }

        const item = bem.findOutside(target, { block: 'ya-share2', elem: 'item' });

        if (!item) {
            return;
        }

        if (bem.getMod(item, 'more')) {
            this._onMoreClick(event);

            return;
        }

        if (bem.getMod(item, 'copy')) {
            this._onCopyClick(event);

            return;
        }

        this._onServiceClick(event, item, metrika);
    }

    _onCopyClick(event) {
        if (this._morePopup && this._morePopup.classList.contains('ya-share2__popup_clipboard')) {
            this._closePopup();
            clip(this._options.get('content.url'), text => {
                prompt(this._i18n.pressToCopy, text); // eslint-disable-line no-alert
            });
        } else if (this._options.get('theme.limit') === false && this._options.get('theme.copy') === 'extraItem') {
            const title = this._options.get('content.title');
            const url = this._options.get('content.url');
            const textToCopy = title + ' ' + url;
            clip(textToCopy, text => {
                prompt(this._i18n.pressToCopy, text); // eslint-disable-line no-alert
            });
        }

        event.preventDefault();
        event.stopPropagation();
    }

    _handleAutoDirection() {
        if (this._options.get('theme.popupDirection') !== 'auto') {
            return;
        }

        const [visibilityClass] = visibilityClasses.filter(className => this._morePopup.classList.contains(className));
        if (visibilityClass) {
            this._morePopup.classList.toggle(visibilityClass);
            return;
        }

        const { y, height } = this._morePopup.getBoundingClientRect();

        const size = this._options.get('theme.size');
        const verticalOffset = size === 'm' ?
            ICON_SIZE_M + POPUP_POSITION_OFFSET_M :
            ICON_SIZE_S + POPUP_POSITION_OFFSET_S;

        const contentHeight = height + verticalOffset;
        if (y - contentHeight > 0 && y + contentHeight > window.innerHeight) {
            this._morePopup.classList.toggle('ya-share2__popup_direction_top');
        } else {
            this._morePopup.classList.toggle('ya-share2__popup_direction_bottom');
        }
    }

    _onMoreClick(event) {
        if (copy()) {
            this._morePopup.classList.add('ya-share2__popup_clipboard');
        } else {
            this._morePopup.classList.remove('ya-share2__popup_clipboard');
        }

        if (this._morePopupContainer) {
            let anchor = bem.findInside(this._domNode, {
                block: 'ya-share2',
                elem: 'item',
                modName: 'more'
            })[0];
            let { top, left, width, height } = dom.getRectRelativeToDocument(anchor);

            this._morePopupContainer.style.top = `${top}px`;
            this._morePopupContainer.style.left = `${left}px`;
            this._morePopupContainer.style.width = `${width}px`;
            this._morePopupContainer.style.height = `${height}px`;
        }

        this._morePopup.classList.toggle('ya-share2__popup_visible');

        this._handleAutoDirection();

        event.preventDefault();
        event.stopPropagation();
    }

    _onServiceClick(event, item, metrika) {
        this._closePopup();

        const serviceName = bem.getMod(item, 'service');

        if (!serviceName) {
            return;
        }

        const service = this._services.filter(service => service.name === serviceName)[0];

        if (!service) {
            return;
        }

        this._options.get('hooks.onshare').call(this, service.name);

        // If `destroy` method was called from the hook
        if (this._isDestroyed()) {
            return;
        }

        const useLinks = this._options.get('theme.useLinks');

        if (!useLinks && service.popupDimensions) {
            const link = bem.findInside(item, {
                block: 'ya-share2',
                elem: 'link'
            })[0];

            event.preventDefault();
            event.stopPropagation();

            popup.open('ya-share2', link.href, service.popupDimensions);
        }

        var items = bem.findInside(this._domNode, { block: 'ya-share2', elem: 'item' });
        var buttonIndex = [].indexOf.call(items, item);

        metrika.getCounter().reachGoal('BUTTON_CLICK', { serviceName, buttonIndex });
    }

    _onMetrikaInited() {
        resolveMetrika();
    }

    isBare() {
        return Boolean(this._options.get('theme.bare'));
    }

    _closePopup() {
        if (this._morePopup) {
            this._morePopup.classList.remove('ya-share2__popup_visible');

            if (this._options.get('theme.popupDirection') === 'auto') {
                for (const className of visibilityClasses) {
                    this._morePopup.classList.remove(className);
                }
            }
        }
    }
}
